import {
	ActionContext,
	ActionTree,
	GetterTree,
	Module,
	MutationTree
} from 'vuex';
import { clamp } from '@/helpers/utils';
import {
	MAX_PLAYER_AP,
	MAX_PLAYER_FP,
	MAX_PLAYER_GP,
	MAX_PLAYER_HP,
	MAX_PLAYER_XP,
	PLAYER_UPDATE_AP,
	PLAYER_UPDATE_FP,
	PLAYER_UPDATE_GP,
	PLAYER_UPDATE_HP,

	PLAYER_UPDATE_POTION,
	PLAYER_UPDATE_EFFECT,

	DUNGEON_UPDATE_AREA,
	DUNGEON_UPDATE_MAT,
	DUNGEON_UPDATE_FLOOR,

	PLAYER_UPDATE_XP,
	STARVATION_COST_HP,
	XP_LEVEL_2,
	XP_LEVEL_3,
	PLAYER_UPDATE_MONSTER_HP,
	GAME_SET_STATE,
	DATABASE_CLEAR,
	DATABASE_SAVE,
	PLAYER_UPDATE_STAT,
	PLAYER_CLEAR_STATE,
	MAX_MONSTER_HP,
	SOUND_PLAY_SFX,
	SOUND_PLAY_AMB,
	GAME_UPDATE_STATE,
} from '@/helpers/consts';
import { store } from '..';
import _ from 'lodash';
import { SoundData } from '@/data/sounddata';
//@ts-ignore
import DungeonData from "../../data/dungeondata.json";

const MutateMonsterHP: string = 'playerModule:mutateMonsterHP';
const MutatePlayerHP: string = 'playerModule:mutatePlayerHP';
const MutatePlayerXP: string = 'playerModule:mutatePlayerXP';
const MutatePlayerGP: string = 'playerModule:mutatePlayerGP';
const MutatePlayerFP: string = 'playerModule:mutatePlayerFP';
const MutatePlayerAP: string = 'playerModule:mutatePlayerAP';

const MutateSetPotion: string = 'playerModule:setPlayerPotion';
const MutatePlayerPotion: string = 'playerModule:mutatePlayerPotion';
const MutatePlayerEffect: string = 'playerModule:mutatePlayerEffect';

const MutateDungeonMat: string = 'playerModule:mutateDungeonMat';
const MutateDungeonFloor: string = 'playerModule:mutateDungeonFloor';
const MutateDungeonArea: string = 'playerModule:mutateDungeonArea';
const MutateGameState: string = 'playerModule:mutateGameState';



export interface PlayerState {
	[index: string]: any;
	mhp: number,
	hp: number,
	xp: number,
	fp: number,
	ap: number,
	gp: number,
	level: number,
	isPoisoned: boolean,
	isCursed: boolean,
	isBlind: boolean,
	potions: string[],
	dungeonMat: string,
	dungeonArea: number,
	dungeonFloor: number,
}

const state: PlayerState = {
	mhp: 0,
	hp: 0,
	xp: 0,
	fp: 0,
	ap: 0,
	gp: 0,
	level: 1,
	isPoisoned: false,
	isCursed: false,
	isBlind: false,
	potions: [],
	dungeonMat: "dungeon",
	dungeonArea: 1,
	dungeonFloor: 1,
}

const cleanState: PlayerState = {
	mhp: 0,
	hp: 0,
	xp: 0,
	fp: 0,
	ap: 0,
	gp: 0,
	level: 1,
	isPoisoned: false,
	isCursed: false,
	isBlind: false,
	potions: [],
	dungeonMat: "dungeon",
	dungeonArea: 1,
	dungeonFloor: 1,
}

const actions: ActionTree<PlayerState, any> = {
	[PLAYER_UPDATE_MONSTER_HP]({ commit, state }: ActionContext<PlayerState, any>, value: number) {
		// console.warn(`ACTION - Player update Monster HP`);
		commit(MutateMonsterHP, value);
		this.dispatch(DATABASE_SAVE);
	},
	[PLAYER_UPDATE_HP]({ commit, state }: ActionContext<PlayerState, any>, value: number) {
		// console.warn(`ACTION - Player update HP`);
		commit(MutatePlayerHP, value);
		this.dispatch(DATABASE_SAVE);
	},
	[PLAYER_UPDATE_XP]({ commit, state }: ActionContext<PlayerState, any>, value: number) {
		// console.warn(`ACTION - Player update XP`);
		commit(MutatePlayerXP, value);
		this.dispatch(DATABASE_SAVE);
	},
	[PLAYER_UPDATE_GP]({ commit, state }: ActionContext<PlayerState, any>, value: number) {
		// console.warn(`ACTION - Player update GP`);
		commit(MutatePlayerGP, value);
		this.dispatch(DATABASE_SAVE);
	},
	[PLAYER_UPDATE_FP]({ commit, state }: ActionContext<PlayerState, any>, value: number) {
		// console.warn(`ACTION - Player update FP`);
		commit(MutatePlayerFP, value);
		this.dispatch(DATABASE_SAVE);
	},
	[PLAYER_UPDATE_AP]({ commit, state }: ActionContext<PlayerState, any>, value: number) {
		// console.warn(`ACTION - Player update AP`);
		commit(MutatePlayerAP, value);
		this.dispatch(DATABASE_SAVE);
	},

	[PLAYER_UPDATE_STAT]({ commit, state }: ActionContext<PlayerState, any>, payload: { type: string, value: string | number }) {
		let actionToUse: string = "";
		switch (payload.type) {
			case 'hp': actionToUse = PLAYER_UPDATE_HP;
				break;
			case 'xp': actionToUse = PLAYER_UPDATE_XP;
				break;
			case 'ap': actionToUse = PLAYER_UPDATE_AP;
				break;
			case 'gp': actionToUse = PLAYER_UPDATE_GP;
				break;
			case 'fp': actionToUse = PLAYER_UPDATE_FP;
				break;
			case 'potion': actionToUse = PLAYER_UPDATE_POTION;
				break;
			case 'effect': actionToUse = PLAYER_UPDATE_EFFECT;
				break;
			default: throw new Error("Wrong type of Player Stat to update.");
		}

		this.dispatch(actionToUse, payload.value);
	},

	[PLAYER_UPDATE_EFFECT]({ commit, state }: ActionContext<PlayerState, any>, effect: string) {
		// console.warn(`ACTION - Player update Effect ${effect}`);
		commit(MutatePlayerEffect, effect);
		this.dispatch(DATABASE_SAVE);
	},
	[PLAYER_UPDATE_POTION]({ commit, state }: ActionContext<PlayerState, any>, potion: string) {
		// console.warn(`ACTION - Player update Potion ${potion}`);
		commit(MutatePlayerPotion, potion);
		this.dispatch(DATABASE_SAVE);
	},
	[DUNGEON_UPDATE_AREA]({ commit, state }: ActionContext<PlayerState, any>, area: number) {
		// console.warn(`ACTION - Dungeon update area: ${area}`);
		commit(MutateDungeonArea, area);
		this.dispatch(DATABASE_SAVE);
	},
	[DUNGEON_UPDATE_FLOOR]({ commit, state }: ActionContext<PlayerState, any>, payload:{floor: number, mat: string}) {
		// console.warn(`ACTION - Dungeon update floor: ${payload.floor}`);
		commit(MutateDungeonFloor, payload.floor);

		let dungeonMat: string;
		if (payload.mat !== null)
		{
			dungeonMat = payload.mat;
		}
		else
		{
			dungeonMat = state.dungeonMat;
		}

		for (let i = 0; i < DungeonData[dungeonMat].floors.length; i++) {
			if (DungeonData[dungeonMat].floors[i].floor == payload.floor)
			{
				this.dispatch(SOUND_PLAY_AMB, DungeonData[dungeonMat].floors[i].ambience);
			}
		}
		this.dispatch(DATABASE_SAVE);
	},

	[DUNGEON_UPDATE_MAT]({ commit, state }: ActionContext<PlayerState, any>, mat: string) {
		this.dispatch(DUNGEON_UPDATE_AREA, 1);
		this.dispatch(DUNGEON_UPDATE_FLOOR, {floor:1, mat:mat});
		commit(MutateDungeonMat, mat);
	},
	[GAME_SET_STATE]({ commit, state }: ActionContext<PlayerState, any>, data: any[]) {
		commit(MutateGameState, data);
	},
	[PLAYER_CLEAR_STATE]({ commit, state }: ActionContext<PlayerState, any>) {
		this.dispatch(GAME_UPDATE_STATE, _.cloneDeep(Object.entries(cleanState)));
	},
	[DATABASE_CLEAR]({ commit, state }: ActionContext<PlayerState, any>) {
		this.dispatch(GAME_UPDATE_STATE, _.cloneDeep(Object.entries(cleanState)));
	},
	[GAME_UPDATE_STATE]({ commit, state }: ActionContext<PlayerState, any>, data: any[]) {
		commit(MutateGameState, data);
	}
}

const mutations: MutationTree<PlayerState> = {
	[MutateMonsterHP](currentState: PlayerState, value: number) {
		currentState.mhp = clamp(currentState.mhp + value, 0, MAX_MONSTER_HP);
	},
	[MutatePlayerHP](currentState: PlayerState, value: number) {
		currentState.hp = clamp(currentState.hp + value, 0, MAX_PLAYER_HP);
	},
	[MutatePlayerXP](currentState: PlayerState, value: number) {
		if ((currentState.xp + value) > MAX_PLAYER_XP) {
			const excessXP: number = (currentState.xp + value) - MAX_PLAYER_XP;
			store.dispatch(PLAYER_UPDATE_HP, excessXP);
		}

		currentState.xp = clamp(currentState.xp + value, 0, MAX_PLAYER_XP);
		if (currentState.xp >= XP_LEVEL_3) {
			currentState.level = 3;
		} else if (currentState.xp >= XP_LEVEL_2) {
			currentState.level = 2;
		} else {
			currentState.level = 1;
		}
	},
	[MutatePlayerGP](currentState: PlayerState, value: number) {
		currentState.gp = clamp(currentState.gp + value, 0, MAX_PLAYER_GP);
	},
	[MutatePlayerFP](currentState: PlayerState, value: number) {
		if (currentState.fp + value < 0) {
			store.dispatch(PLAYER_UPDATE_HP, STARVATION_COST_HP);
		}
		currentState.fp = clamp(currentState.fp + value, 0, MAX_PLAYER_FP);
	},
	[MutatePlayerAP](currentState: PlayerState, value: number) {
		currentState.ap = clamp(currentState.ap + value, 0, MAX_PLAYER_AP);
	},
	[MutatePlayerEffect](currentState: PlayerState, effect: string) {
		switch (effect) {
			case 'poison': currentState.isPoisoned = !currentState.isPoisoned;
				break;
			case 'curse': currentState.isCursed = !currentState.isCursed;
				break;
			case 'blindness': currentState.isBlind = !currentState.isBlind;
				break;
		}
	},
	[MutateSetPotion](currentState: PlayerState, potion: string) {
		const potionIndex: number = currentState.potions.indexOf(potion);
		if (potionIndex == -1) {
			currentState.potions.push(potion);
		}
	},
	[MutatePlayerPotion](currentState: PlayerState, potion: string) {
		const potionIndex: number = currentState.potions.indexOf(potion);
		if (potionIndex == -1) {
			if (store.state.SettingsModule.strictRules) {
				if (currentState.potions.length < 2) {
					switch (potion)
					{
						case "fire": store.dispatch(SOUND_PLAY_SFX, SoundData.UI_Menu_Potion_Fire_Gain); break;
						case "frost": store.dispatch(SOUND_PLAY_SFX, SoundData.UI_Menu_Potion_Frost_Gain); break;
						case "poison": store.dispatch(SOUND_PLAY_SFX, SoundData.UI_Menu_Potion_Poison_Gain); break;
						case "healing": store.dispatch(SOUND_PLAY_SFX, SoundData.UI_Menu_Potion_Heal_Gain); break;
						case "holywater": store.dispatch(SOUND_PLAY_SFX, SoundData.UI_Menu_Potion_Holy_Gain); break;
						case "perception": store.dispatch(SOUND_PLAY_SFX, SoundData.UI_Menu_Potion_Perception_Gain); break;
					}
					currentState.potions.push(potion);
				}
				else {
					console.warn("No can do, trying to add one potion too much.");
				}
			}
			else {
				switch (potion)
				{
					case "fire": store.dispatch(SOUND_PLAY_SFX, SoundData.UI_Menu_Potion_Fire_Gain); break;
					case "frost": store.dispatch(SOUND_PLAY_SFX, SoundData.UI_Menu_Potion_Frost_Gain); break;
					case "poison": store.dispatch(SOUND_PLAY_SFX, SoundData.UI_Menu_Potion_Poison_Gain); break;
					case "healing": store.dispatch(SOUND_PLAY_SFX, SoundData.UI_Menu_Potion_Heal_Gain); break;
					case "holywater": store.dispatch(SOUND_PLAY_SFX, SoundData.UI_Menu_Potion_Holy_Gain); break;
					case "perception": store.dispatch(SOUND_PLAY_SFX, SoundData.UI_Menu_Potion_Perception_Gain); break;
				}
				currentState.potions.push(potion);
			}
		}
		else {
			store.dispatch(SOUND_PLAY_SFX, SoundData.UI_Menu_Potion_Loss);
			currentState.potions.splice(potionIndex, 1);
		}
	},
	[MutateDungeonArea](currentState: PlayerState, area: number) {
		currentState.dungeonArea = area;
	},
	[MutateDungeonFloor](currentState: PlayerState, floor: number) {
		currentState.dungeonFloor = floor;
	},
	[MutateDungeonMat](currentState: PlayerState, mat: string) {
		currentState.dungeonMat = mat;
	},
	[MutateGameState](currentState: PlayerState, data: any[]) {
		data.forEach((dataItem: any) => {
			const [key, value] = dataItem;
			currentState[key] = value;
		})
	},


}

const getters: GetterTree<PlayerState, any> = {
	// empty
	selectedMat: (state: PlayerState) => {
		return DungeonData[state.dungeonMat];
	},
	dungeonMats: () => {
		return DungeonData;
	}
}

export const PlayerModule: Module<PlayerState, any> = {
	state,
	actions,
	mutations,
	getters,
}