
// @ is an alias to /src
import Vue from 'vue';

import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { SOUND_PLAY_SFX } from '@/helpers/consts';
import { SoundData } from '@/data/sounddata';

@Component({
  name: "Home",
  computed: {
	...mapState({
		hasStartedGame: (state: any) => state.GameModule.hasStartedGame,
	}),
  }
})
export default class Home extends Vue {
	PlaySound()
	{
		this.$store.dispatch(SOUND_PLAY_SFX, SoundData.UI_Click);
	}
};

