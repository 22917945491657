import { DATABASE_SAVE, SETTINGS_SET_HIDDEN_INFO, SETTINGS_SET_STRICT_RULES, SETTINGS_TOGGLE_HIDDEN_INFO, SETTINGS_TOGGLE_STRICT_RULES } from "@/helpers/consts";
import { ActionContext, ActionTree, GetterTree, Module, MutationTree } from "vuex";

const MutateStrictRules: string = 'settingsModule:mutateStrictRules';
const MutateHiddenInfo: string = 'settingsModule:mutateHiddenInfo';

export interface SettingsState {
	strictRules: boolean;
	hiddenInfo: boolean;
}

const state: SettingsState = {
	strictRules: true,
	hiddenInfo: false,
}

const actions: ActionTree<SettingsState, any> = {
	[SETTINGS_TOGGLE_STRICT_RULES]({ commit, state }: ActionContext<SettingsState, any>) {
		// console.warn(`ACTION - Settings Toggle Strict Rules`);
		commit(MutateStrictRules);
		this.dispatch(DATABASE_SAVE);
	},
	[SETTINGS_SET_STRICT_RULES]({ commit, state }: ActionContext<SettingsState, any>, value: boolean) {
		// console.warn(`ACTION - Settings Set Strict Rules: ${value}`);
		commit(MutateStrictRules, value);
	},
	[SETTINGS_TOGGLE_HIDDEN_INFO]({ commit, state }: ActionContext<SettingsState, any>) {
		// console.warn(`ACTION - Settings Toggle Hidden Info`);
		commit(MutateHiddenInfo);
		this.dispatch(DATABASE_SAVE);
	},
	[SETTINGS_SET_HIDDEN_INFO]({ commit, state }: ActionContext<SettingsState, any>, value: boolean) {
		// console.warn(`ACTION - Settings Set Hidden Info: ${value}`);
		commit(MutateHiddenInfo, value);
	},
}

const mutations: MutationTree<SettingsState> = {
	[MutateStrictRules](currentState: SettingsState, payload: boolean) {
		if (payload !== undefined) {
			currentState.strictRules = payload;
		}
		else {
			currentState.strictRules = !currentState.strictRules;
		}
	},
	[MutateHiddenInfo](currentState: SettingsState, payload: boolean) {
		if (payload !== undefined) {
			currentState.hiddenInfo = payload;
		}
		else {
			currentState.hiddenInfo = !currentState.hiddenInfo;
		}
	},
}

const getters: GetterTree<SettingsState, any> = {
}

export const SettingsModule: Module<SettingsState, any> = {
	state,
	actions,
	mutations,
	getters,
}
