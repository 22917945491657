import Container from './Container';

export default class RandomContainer extends Container {
	lastPlayedIDs: number[] = [];
	nbRememberedIDs: number = 0;

	override Play(volume: number = 1, rate: number = 1, pan = 0): Container {
		let soundID: number = this._getNextID();
		if (this.nbRememberedIDs === 0)
		{
			soundID = 0;
			this.lastPlayedIDs = [];
		}
		else if (this.lastPlayedIDs.length === this.nbRememberedIDs) 
		{
			this.lastPlayedIDs.shift();
		}
		this.lastPlayedIDs.push(soundID);

		const randomContainer: Container = this.GetChildAt(soundID);
		randomContainer.onPlay.addOnce(() => this.onPlay.dispatch(-1));
		randomContainer.onEnd.addOnce(() => this.onEnd.dispatch(-1));
		randomContainer.Play(volume * this.volume, rate * this.rate, this.pan);
		return this;
	}

	private _getNextID() {
		let randomID: number = Math.floor(Math.random() * this.Count);
		while (this.lastPlayedIDs.indexOf(randomID) > -1) randomID++;
		if (randomID >= this.Count) randomID = 0;
		while (this.lastPlayedIDs.indexOf(randomID) > -1) randomID++;
		return randomID;
	}
}
