
import Vue from "vue";
import Component from "vue-class-component";
import ValueTracker from "@/components/ValueTracker.vue";
import PotionToggle from "@/components/PotionToggle.vue";
import EffectToggle from "@/components/EffectToggle.vue";
import { mapGetters, mapState } from "vuex";

@Component({
    name: "CharacterMatView",
    components: {
        ValueTracker,
        PotionToggle,
        EffectToggle,
    },
    computed: {
        ...mapState({
            monsterHP: (state: any) => state.PlayerModule.mhp,
            playerHP: (state: any) => state.PlayerModule.hp,
            playerXP: (state: any) => state.PlayerModule.xp,
            playerAP: (state: any) => state.PlayerModule.ap,
            playerFP: (state: any) => state.PlayerModule.fp,
            playerGP: (state: any) => state.PlayerModule.gp,
            playerCursed: (state: any) => state.PlayerModule.isCursed,
            playerPoisoned: (state: any) => state.PlayerModule.isPoisoned,
            playerBlind: (state: any) => state.PlayerModule.isBlind,

            hasPotionFire: (state: any) =>
                state.PlayerModule.potions.indexOf("fire") > -1,
            hasPotionFrost: (state: any) =>
                state.PlayerModule.potions.indexOf("frost") > -1,
            hasPotionPoison: (state: any) =>
                state.PlayerModule.potions.indexOf("poison") > -1,
            hasPotionHealing: (state: any) =>
                state.PlayerModule.potions.indexOf("healing") > -1,
            hasPotionHolywater: (state: any) =>
                state.PlayerModule.potions.indexOf("holywater") > -1,
            hasPotionPerception: (state: any) =>
                state.PlayerModule.potions.indexOf("perception") > -1,

            playerLevel: (state: any) => state.PlayerModule.level,
        }),
        ...mapGetters(["getSelectedCharacterId"]),
    },
})
export default class CharacterMatView extends Vue {

	hasPotionHealing!: boolean;
	playerPoisoned!: boolean;
	playerCursed!: boolean;
	playerBlind!: boolean;
	playerHP!: number;
	monsterHP!: number;
	playerXP!: number;
	playerAP!: number;
	playerFP!: number;
	playerGP!: number;
	playerLevel!: number;
	hasPotionFire!: boolean;
	hasPotionFrost!: boolean;
	hasPotionPoison!: boolean;
	hasPotionHolywater!: boolean;
	hasPotionPerception!: boolean;

    restartGame() {
        window.location.reload();
    }
}
