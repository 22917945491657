
import Vue from "vue";
import Component from "vue-class-component";
import LoreButton from "@/components/LoreButton.vue";
import LoreSelectedChoice from "@/components/LoreSelectedChoice.vue";
import { SOUND_PLAY_SFX } from "@/helpers/consts";
import { SoundData } from "@/data/sounddata";

@Component({
    name: "LoreChoiceView",
	components: {
		LoreButton,
		LoreSelectedChoice
	},
	props: {
		lore: Object,
    },
})
export default class LoreChoiceView extends Vue {

	onSelectChoice(loreObject: any)
	{
		this.$store.dispatch(SOUND_PLAY_SFX, SoundData.UI_Click);
		this.$buefy.modal.open({
			parent: this,
			component: LoreSelectedChoice,
			trapFocus: true,
			canCancel: false,
			props: {
				lore: loreObject,
			}
		})
	}

}
