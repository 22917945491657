
import Vue from 'vue';
import Component from 'vue-class-component';
import Icon from '@/components/Icon.vue';
import { store } from '@/store';
import { SOUND_PLAY_SFX } from '@/helpers/consts';
import { SoundData } from '@/data/sounddata';

@Component({
	name: 'ValueTracker',
	props: {
		track: String,
		value: Number,
		icon: String,
		isMonster: Boolean,
	},
	components: {
		Icon,
	}

})
export default class ValueTracker extends Vue
{
	updatePlayerValue(track: string, value: number)
	{
		this.$store.dispatch(`playerModule:update_${track}`, value);

		if (value < 0) {
			switch (track) {
				case "playerGP":
					store.dispatch(SOUND_PLAY_SFX, SoundData.UI_Menu_Gold_Loss);
					break;
				case "playerFP":
					store.dispatch(SOUND_PLAY_SFX, SoundData.UI_Menu_Food_Loss);
					break;
				case "playerAP":
					store.dispatch(SOUND_PLAY_SFX, SoundData.UI_Menu_Armor_Loss);
					break;
				case "playerHP":
				case "monsterHP":
					store.dispatch(SOUND_PLAY_SFX, SoundData.UI_Menu_HP_Loss);
					break;
				case "playerXP":
					store.dispatch(SOUND_PLAY_SFX, SoundData.UI_Menu_XP_Loss);
					break;
				default:
					break;
			}
		}

		if (value > 0) {
			switch (track) {
				case "playerGP":
					store.dispatch(SOUND_PLAY_SFX, SoundData.UI_Menu_Gold_Gain);
					break;
				case "playerFP":
					store.dispatch(SOUND_PLAY_SFX, SoundData.UI_Menu_Food_Gain);
					break;
				case "playerAP":
					store.dispatch(SOUND_PLAY_SFX, SoundData.UI_Menu_Armor_Gain);
					break;
				case "playerHP":
				case "monsterHP":
					store.dispatch(SOUND_PLAY_SFX, SoundData.UI_Menu_HP_Gain);
					break;
				case "playerXP":
					store.dispatch(SOUND_PLAY_SFX, SoundData.UI_Menu_XP_Gain);
					break;
				default:
					break;
			}
		}
	}
}
