import { random } from "@/helpers/utils";
import _ from "lodash";
import { Signal } from "@robotlegsjs/signals";
import { Range } from "@/types/Range";
import * as Tone from 'tone';

//#########################################
// Base class for all containers
//#########################################
export default class Container {
	//#########################################
	// S I G N A L S
	//#########################################
	onLoad: Signal = new Signal();
	onLoaderror: Signal = new Signal();
	onPlayerror: Signal = new Signal();
	onPlay: Signal = new Signal();
	onEnd: Signal = new Signal();
	onPause: Signal = new Signal();
	onstop: Signal = new Signal();
	onMute: Signal = new Signal();
	onVolume: Signal = new Signal();
	onRate: Signal = new Signal();
	onSeek: Signal = new Signal();
	onFade: Signal = new Signal();
	onUnlock: Signal = new Signal();

	//#########################################
	// P R I V A T E   P R O P S
	//#########################################
	protected _children: Array<Container> = [];
	private _channel: Tone.Channel;
	private _vol: Range = {from: 1, to: 1};
	private _delay: Range = {from: 10, to: 10};
	private _rate: Range = {from: 1.0, to: 1.0};
	private _pan: Range = {from: 0, to: 0};
	private _loop: boolean = false;
	
	//#########################################
	// A C C E S S O R S
	//#########################################
	public get Count(): number
	{
		return this._children.length;
	}

	public get volume() : number 
	{
		return random(this._vol.to, this._vol.from);
	}

	public get rate() : number 
	{
		return random(this._rate.from, this._rate.to);
	}

	public get pan() : number 
	{
		return random(this._pan.from, this._pan.to);
	}

	public get delayValue() : number
	{
		const output = random(this._delay.to, this._delay.from);
		return output;
	}

	public set volRange(v : Range) {
		this._vol = v;
	}

	public set delayRange(v : Range) {
		this._delay = v;
	}

	public set rateRange(v : Range) {
		this._rate = v;
	}

	public set panRange(v : Range) {
		this._pan = v;
	}

	public set loop(v : boolean) {
		this._loop = v;
	}

	public get loop() : boolean {
		return this._loop;
	}

	constructor(channel : Tone.Channel)
	{
		this._channel = channel;
	}	

	//#########################################
	// P U B L I C   M E T H O D S
	//#########################################
	public GetChildAt(index: number): Container
	{
		return this._children[index];
	}

	public Add(containers: Container | Container[]) 
	{
		if (Array.isArray(containers)) {
			this._children.concat(containers);
		} else {
			this._children.push(containers);
		}
	}

	public Play(volume: number = 1, rate:number = 1, pan: number = 0): Container
	{
		this._children.forEach((container: Container) => {
			container.Play(volume * this.volume, rate * this.rate, this.pan);
		});
		return this;
	}
	
	public Stop() 
	{
		this._children.forEach((container: Container) => {
			container.onstop.removeAll();
			container.Stop();
		});
	}
}
