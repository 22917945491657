
import { GAME_SELECT_NEXT_CHARACTER, GAME_SELECT_PREV_CHARACTER, GAME_INIT } from "@/helpers/consts";
import Vue from "vue";
import Icon from "@/components/Icon.vue"
import Component from "vue-class-component";
import { mapGetters } from "vuex";

@Component({
	name: "SelectCharacterView",
	components: {
		Icon,
	},
	computed: {
		...mapGetters(['getSelectedCharacterId', 'getSelectedCharacterModifiers']),
	}
})
export default class SelectCharacterView extends Vue {
	getSelectedCharacterId: string;
	getSelectedCharacterModifiers: any[];

	selectNextCharacter() {
		this.$store.dispatch(GAME_SELECT_NEXT_CHARACTER);
	}

	selectPrevCharacter() {
		this.$store.dispatch(GAME_SELECT_PREV_CHARACTER);
	}

	startGame() {
		this.$store.dispatch(GAME_INIT, true);
	}
}
