
import { PLAYER_UPDATE_POTION } from "@/helpers/consts";
import Vue from "vue";
import Component from "vue-class-component";

@Component({
    name: "PotionToggle",
    props: {
        potion: String,
        icon: String,
        toggled: Boolean,
    },
})
export default class PotionToggle extends Vue {
    updatePotionState(potion: string, currentToggleState: boolean) {
        this.$store.dispatch(PLAYER_UPDATE_POTION, potion);
    }
}
