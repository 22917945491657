import { ChannelType } from "@/enums/ChannelType";
import ContainerFactory from "@/factories/ContainerFactory";
import { DATABASE_SAVE, SOUND_PLAY_SFX, SOUND_SET_MASTER_VOLUME, SOUND_STOP_SFX, SOUND_UPDATE_MASTER_VOLUME, SOUND_TOGGLE_MUTE, SOUND_SET_MUTE, SOUND_UPDATE_SFX_VOLUME, SOUND_UPDATE_AMB_VOLUME, SOUND_PLAY_AMB, SOUND_SET_AMB_VOLUME, SOUND_SET_SFX_VOLUME } from "@/helpers/consts";
import ISoundData from "@/interfaces/ISoundData";
import Container from "@/sound/Container";
import SoundContainer from "@/sound/SoundContainer";
import { ActionContext, ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { store } from "..";
import * as Tone from 'tone';
// @ts-ignore
import sounddata from "../../data/sounddata.json";

const MutateMasterVolume: string = "soundModule:mutateMasterVolume";
const SetMasterVolume: string = "soundModule:setMasterVolume";
const SetAmbientVolume: string = "soundModule:setAmbientVolume";
const MutateAmbientVolume: string = "soundModule:mutateAmbientVolume";
const SetSfxVolume: string = "soundModule:setSfxVolume";
const MutateSfxVolume: string = "soundModule:mutateSfxVolume";
const ToggleMute: string = "soundModule:toggleMute";
const SetMute: string = "soundModule:setMute";

export interface SoundState
{
	masterChannel: Tone.Channel,
	sfxChannel: Tone.Channel,
	ambChannel: Tone.Channel,

	containers: { [name: string]: Container };
	masterVolume: number;
	sfxVolume: number;
	ambVolume: number;
	isMuted: boolean;
	isToneRunning: boolean;
	loadedSounds: string;
	currentAmbience:Container | null;
}

const state: SoundState = {
	containers: {},
	masterVolume: 0,
	sfxVolume: 0,
	ambVolume: 0,
	isMuted: false,
	isToneRunning: false,
	loadedSounds: "",
	masterChannel: new Tone.Channel(),
	sfxChannel: new Tone.Channel(),
	ambChannel: new Tone.Channel(),
	currentAmbience: null,
}

let soundsToLoad: number = 0;
let loadedSounds: number = 0;

function init() {
	state.masterChannel = new Tone.Channel().toDestination();
	state.sfxChannel = new Tone.Channel(-1, 0).connect(state.masterChannel);
	state.ambChannel = new Tone.Channel(1, 0).connect(state.masterChannel);

	sounddata.forEach(extractContainer);

	window.addEventListener("load", () => {
		document.body.addEventListener("pointerdown", resumeAudioContext);
		document.body.addEventListener("click", resumeAudioContext);
	});
}


function resumeAudioContext() {
	Tone.start();
	Tone.context.resume().then(_ => {
		if (Tone.context.state == "running") {
			state.isToneRunning = true;
			document.body.removeEventListener("pointerdown", resumeAudioContext);
			document.body.removeEventListener("click", resumeAudioContext);
		}
	});
}

function getChannelByName(name: string): Tone.Channel
{
	switch (name) {
		case ChannelType.Sfx: return state.sfxChannel;
		case ChannelType.Amb: return state.ambChannel;
		default: return state.masterChannel;
	}
}

function extractContainer(data: ISoundData | string, soundChannel : Tone.Channel, isLoop: boolean = false): Container
{
	if (typeof data === "string") {
		if (data.indexOf(".") >= 0)
		{
			soundsToLoad++;
			const sound = new SoundContainer(data, soundChannel, isLoop);
			sound.onLoad.addOnce(() => {
				loadedSounds++;
				state.loadedSounds = `${loadedSounds}/${soundsToLoad}`;
			});
			return sound;
		}
		else
		{
			console.log("This is a container name, not a sound: " + data);
			return state.containers[data];
		}
	}

	const containerType = data.container_type;
	const containerChildren = data.children;

	const channel: Tone.Channel = getChannelByName(data.channel);
	let newContainer: Container = ContainerFactory.createContainer(containerType, channel, data);

	if (data.volume) newContainer.volRange = data.volume;
	if (data.delay) newContainer.delayRange = data.delay;
	if (data.rate) newContainer.rateRange = data.rate;
	if (data.pan) newContainer.panRange = data.pan;
	if (data.loop) newContainer.loop = data.loop;

	containerChildren.forEach((data: ISoundData | string) =>
	{
		newContainer.Add(extractContainer(data, channel, newContainer.loop));
	});

	state.containers[data.name] = newContainer;

	return newContainer;
}


const actions: ActionTree<SoundState, any> = {
	[SOUND_UPDATE_MASTER_VOLUME]({ commit, state }: ActionContext<SoundState, any>, value: number)
	{
		commit(MutateMasterVolume, value);
	},
	[SOUND_SET_MASTER_VOLUME]({ commit, state }: ActionContext<SoundState, any>, value: number)
	{
		commit(SetMasterVolume, value);
	},

	[SOUND_UPDATE_SFX_VOLUME]({ commit, state }: ActionContext<SoundState, any>, value: number)
	{
		commit(MutateSfxVolume, value);
	},
	[SOUND_SET_SFX_VOLUME]({ commit, state }: ActionContext<SoundState, any>, value: number)
	{
		commit(SetSfxVolume, value);
	},

	[SOUND_UPDATE_AMB_VOLUME]({ commit, state }: ActionContext<SoundState, any>, value: number)
	{
		commit(MutateAmbientVolume, value);
	},
	[SOUND_SET_AMB_VOLUME]({ commit, state }: ActionContext<SoundState, any>, value: number)
	{
		commit(SetAmbientVolume, value);
	},

	[SOUND_PLAY_SFX]({ commit, state }: ActionContext<SoundState, any>, value: string)
	{
		if (!state.isToneRunning) return;
		if (state.containers[value] !== undefined) {
			state.containers[value].Play();
		}
	},
	[SOUND_PLAY_AMB]({ commit, state }: ActionContext<SoundState, any>, value: string)
	{
		if (!state.isToneRunning) return;
		if (state.currentAmbience !== null && state.currentAmbience != state.containers[value])
		{
			state.currentAmbience.Stop();
		}

		if (state.containers[value] !== undefined) {
			const container = state.containers[value];
			container.Play();
			state.currentAmbience = container;
		}
		else
		{
			console.warn("Ambience not found", value);
		}
	},
	[SOUND_STOP_SFX]({ commit, state }: ActionContext<SoundState, any>, value: string)
	{
		if (!state.isToneRunning) return;
		if (value != null && value != undefined) {
			if (state.containers[value] !== undefined) {
				state.containers[value].Stop();
			}
		}
		else {
			Object.values(state.containers).forEach((container: Container) =>
			{
				container.Stop();
			});
		}

	},
	[SOUND_TOGGLE_MUTE]({ commit, state }: ActionContext<SoundState, any>)
	{
		commit(ToggleMute);
	},
	[SOUND_SET_MUTE]({ commit, state }: ActionContext<SoundState, any>, value: boolean)
	{
		commit(SetMute, value);
	}
}

const mutations: MutationTree<SoundState> = {
	[MutateMasterVolume](currentState: SoundState, value: number)
	{
		currentState.masterVolume = value;
		currentState.masterChannel.volume.value = value;
		store.dispatch(DATABASE_SAVE);
	},
	[SetMasterVolume](currentState: SoundState, value: number)
	{
		currentState.masterVolume = value;
		currentState.masterChannel.volume.value = value;
	},
	[ToggleMute](currentState: SoundState)
	{
		currentState.isMuted = !currentState.isMuted;
		currentState.masterChannel.mute = currentState.isMuted;
		currentState.sfxChannel.mute = currentState.isMuted;
		currentState.ambChannel.mute = currentState.isMuted;
		store.dispatch(DATABASE_SAVE);
	},
	[SetMute](currentState: SoundState, value: boolean)
	{
		currentState.isMuted = value
		currentState.masterChannel.mute = currentState.isMuted;
		currentState.sfxChannel.mute = currentState.isMuted;
		currentState.ambChannel.mute = currentState.isMuted;
	},
	[MutateSfxVolume](currentState: SoundState, value: number) 
	{
		currentState.sfxVolume = value;
		currentState.sfxChannel.volume.value = value;
		store.dispatch(DATABASE_SAVE);
	},
	[SetSfxVolume](currentState: SoundState, value: number)
	{
		currentState.sfxVolume = value;
		currentState.sfxChannel.volume.value = value;
	},
	[MutateAmbientVolume](currentState: SoundState, value: number) 
	{
		currentState.ambVolume = value;
		currentState.ambChannel.volume.value = value;
		store.dispatch(DATABASE_SAVE);
	},
	[SetAmbientVolume](currentState: SoundState, value: number) 
	{
		currentState.ambVolume = value;
		currentState.ambChannel.volume.value = value;
	},
}

const getters: GetterTree<SoundState, any> = {
	// empty
}

init();
export const SoundModule: Module<SoundState, any> = {
	state,
	actions,
	mutations,
	getters,
}