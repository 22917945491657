
import Vue from "vue";
import Component from "vue-class-component";
import { mapGetters, mapState } from "vuex";
import DungeonGridArea from "@/components/DungeonGridArea.vue";
import ValueTracker from "@/components/ValueTracker.vue";
import PotionToggle from "@/components/PotionToggle.vue";
import EffectToggle from "@/components/EffectToggle.vue";
import { SoundData } from "@/data/sounddata";
import { DUNGEON_UPDATE_MAT, SOUND_PLAY_SFX } from "@/helpers/consts";

@Component({
	name: "DungeonMatView",
	components: {
		DungeonGridArea,
		ValueTracker,
		PotionToggle,
		EffectToggle,
	},
	watch:{
		dungeonArea: function (val: number, oldVal: number) {
			if (val != oldVal)
			{
				this.$store.dispatch(SOUND_PLAY_SFX, SoundData.UI_Click);
			}
		},
	},
	computed: {
		...mapState({
			dungeonFloor: (state: any) => state.PlayerModule.dungeonFloor,
			dungeonArea: (state: any) => state.PlayerModule.dungeonArea,
			monsterHP: (state: any) => state.PlayerModule.mhp,
			playerHP: (state: any) => state.PlayerModule.hp,
			playerXP: (state: any) => state.PlayerModule.xp,
			playerAP: (state: any) => state.PlayerModule.ap,
			playerFP: (state: any) => state.PlayerModule.fp,
			playerGP: (state: any) => state.PlayerModule.gp,
			playerCursed: (state: any) => state.PlayerModule.isCursed,
			playerPoisoned: (state: any) => state.PlayerModule.isPoisoned,
			playerBlind: (state: any) => state.PlayerModule.isBlind,

			hasPotionFire: (state: any) =>
				state.PlayerModule.potions.indexOf("fire") > -1,
			hasPotionFrost: (state: any) =>
				state.PlayerModule.potions.indexOf("frost") > -1,
			hasPotionPoison: (state: any) =>
				state.PlayerModule.potions.indexOf("poison") > -1,
			hasPotionHealing: (state: any) =>
				state.PlayerModule.potions.indexOf("healing") > -1,
			hasPotionHolywater: (state: any) =>
				state.PlayerModule.potions.indexOf("holywater") > -1,
			hasPotionPerception: (state: any) =>
				state.PlayerModule.potions.indexOf("perception") > -1,
		}),
		selectedMat : {
			get() {
				return this.$store.getters.selectedMat;
			},
			set(value) {
				this.$store.dispatch(DUNGEON_UPDATE_MAT, value.id);
			}
		},
		...mapGetters(['dungeonMats']),
	}
})
export default class DungeonMatView extends Vue
{


}
