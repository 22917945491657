export function clamp(value: number, min: number, max: number) 
{
    let resultValue: number = value < min ? min : value;
    return resultValue > max ? max : resultValue;
}
export function random(min: number, max: number) : number 
{
	return parseFloat((Math.random() * (max - min) + min).toFixed(3));
}

export function clampRange(minIn: number, maxIn: number, minOut: number, maxOut: number, valueIn: number) : number
{
	const firstRange = maxIn - minIn; // ex : (-100, 0) :: 100
	const ratioValueIn = valueIn - minIn; // ex : 0 -- 100 = 100
	const ratio = ratioValueIn / firstRange;
	const secondRange = maxOut - minOut;
	return ratio * secondRange + minOut;
}

export function clampPan(value: number) : number
{
	if (value == 0) return 1;

	if (value < 0)
	{
		const result = clampRange(-1, 0, 0.5, 1, value);
		console.log(`LEFT Clamp (-1, 0) (0.5, 1) (${value}) ${result}`); 
		return result;
	}

	const result = clampRange(0, 1, 1, 2, value);
	console.log(`RIGHT Clamp (0, 100) (1, 2) (${value}) ${result}`); 
	return result;
}