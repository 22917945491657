
import { SoundData } from '@/data/sounddata';
import { PLAYER_UPDATE_EFFECT, SOUND_PLAY_SFX } from '@/helpers/consts';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
    name: 'EffectToggle',
    props: {
        effect: String,
        icon: String,
		toggled: Boolean,
    },

})
export default class EffectToggle extends Vue 
{
    updateEffectState(effect: string) {
		this.$store.dispatch(PLAYER_UPDATE_EFFECT, effect);

		if (this.$props.toggled)
		{
			this.$store.dispatch(SOUND_PLAY_SFX, SoundData.UI_Menu_Effect_Remove);
		}
		else
		{
			switch (effect) 
			{
				case 'blindness': this.$store.dispatch(SOUND_PLAY_SFX, SoundData.UI_Menu_Effect_Blindness); break;
				case 'curse': this.$store.dispatch(SOUND_PLAY_SFX, SoundData.UI_Menu_Effect_Curse); break;
				case 'poison': this.$store.dispatch(SOUND_PLAY_SFX, SoundData.UI_Menu_Effect_Poison); break;
			}
		}

    }
}
