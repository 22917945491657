
import { GAME_SET_DIFFICULTY } from '@/helpers/consts';
import { router } from '@/router';
import Vue from 'vue'
import Component from "vue-class-component";

@Component({
    name: 'SelectDifficultyView',


})

export default class SelectDifficultyView extends Vue {
	isDifficultySelected: boolean = false;
    selectDifficulty(value : number)
	{
		this.isDifficultySelected = true;
		this.$store.dispatch(GAME_SET_DIFFICULTY, value);
	}

    selectCharacter(value: number)
	{
		this.isDifficultySelected = true;
		this.$store.dispatch(GAME_SET_DIFFICULTY, value);
		router.push({name:'SelectCharacter'});
	}
};
