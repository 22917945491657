
import Vue from "vue";
import Component from "vue-class-component";
import Dice from "@/components/Dice.vue";
import ValueTracker from "@/components/ValueTracker.vue";
import { mapGetters, mapState } from "vuex";
import { PLAYER_UPDATE_HP, PLAYER_UPDATE_MONSTER_HP, SOUND_PLAY_SFX } from "@/helpers/consts";
import { SoundData } from "@/data/sounddata";
@Component({
    name: "DicePoolView",
    components: {
        Dice,
		ValueTracker,
    },
    computed: {
        ...mapState({
            monsterHP: (state: any) => state.PlayerModule.mhp,
            playerHP: (state: any) => state.PlayerModule.hp,
            playerXP: (state: any) => state.PlayerModule.xp,
            playerAP: (state: any) => state.PlayerModule.ap,
            playerFP: (state: any) => state.PlayerModule.fp,
            playerGP: (state: any) => state.PlayerModule.gp,
            playerCursed: (state: any) => state.PlayerModule.isCursed,
            playerPoisoned: (state: any) => state.PlayerModule.isPoisoned,
            playerBlind: (state: any) => state.PlayerModule.isBlind,

            hasPotionFire: (state: any) =>
                state.PlayerModule.potions.indexOf("fire") > -1,
            hasPotionFrost: (state: any) =>
                state.PlayerModule.potions.indexOf("frost") > -1,
            hasPotionPoison: (state: any) =>
                state.PlayerModule.potions.indexOf("poison") > -1,
            hasPotionHealing: (state: any) =>
                state.PlayerModule.potions.indexOf("healing") > -1,
            hasPotionHolywater: (state: any) =>
                state.PlayerModule.potions.indexOf("holywater") > -1,
            hasPotionPerception: (state: any) =>
                state.PlayerModule.potions.indexOf("perception") > -1,

            playerLevel: (state: any) => state.PlayerModule.level,
        }),
        ...mapGetters(["getSelectedCharacterId"]),

    },
})

export default class DicePoolView extends Vue {

	playerCursed: boolean;
	playerPoisoned: boolean;
	playerLevel: number;

	playerDice1: Dice;
	playerDice2: Dice;
	playerDice3: Dice;
	ep: Dice;
	ec: Dice;
	dd: Dice;

	poolValue: number = 0;
	isCursedRoll: boolean = false;
	isPoisonedRoll: boolean = false;

	isComponentModalActive: boolean = true;

	created()
	{
		this.playerDice1 = new Dice();
		this.playerDice2 = new Dice();
		this.playerDice3 = new Dice();
		this.ep = new Dice();
		this.ec = new Dice();
		this.dd = new Dice();
	}

    restartGame() {
        window.location.reload();
    }

	register(handle: string, dice: Dice)
	{
		switch (handle)
		{
			case "playerDice1":
			case "playerDice2":
			case "playerDice3":
			case "ep":
			case "ec":
			case "dd":
				this[handle] = dice;
				break;
			default:
				// console.warn("Dice unknown.");
		}
	}

	updateDice()
	{
		this.getPoolValue(true);
	}

	rollDicePool()
	{
		this.playerDice1.roll({reset: true});
		this.playerDice2.roll({reset: true});
		this.playerDice3.roll({reset: true});
		this.ep.roll({reset: true});
		this.ec.roll({reset: true});
		this.dd.roll({reset: true});

		this.getPoolValue();
		this.$store.dispatch(SOUND_PLAY_SFX, SoundData.UI_Dice);
	}

	applyPoisonDamage() {
		this.$store.dispatch(PLAYER_UPDATE_HP, -1);
		this.$buefy.toast.open({
			duration: 3500,
			message: '<span class="title" ><span class="mr_icons effect_poison">P</span> : -1 <span class="mr_icons hp">h</span></span>',
			position: "is-top",
			type: "is-light"
		})
	}

	getPoolValue(hasUsedFeat: boolean = false)
	{
		// Resolve Effects first
		if (!hasUsedFeat)
		{
			this.isCursedRoll = this.playerCursed ? this.ec.currentIndex > 2 : false;
			this.isPoisonedRoll = this.playerPoisoned ? this.ep.currentIndex > 2 : false;
		
			if (this.isPoisonedRoll) 
			{
				this.applyPoisonDamage();
			}
		}

		this.poolValue = 0;

		if (this.playerLevel >= 3) 
		{
			this.poolValue += this.getPlayerDiceValue(this.playerDice3)
		}

		if (this.playerLevel >= 2) 
		{
			this.poolValue += this.getPlayerDiceValue(this.playerDice2)
		}

		if (this.playerLevel >= 1) 
		{
			this.poolValue += this.getPlayerDiceValue(this.playerDice1)
		}
	}

	getPlayerDiceValue(playerDice : Dice) : number
	{
		if (playerDice.currentIndex === 0) return 0;
		if (this.isCursedRoll)
		{
			return playerDice.currentIndex;
		}
		else 
		{
			return playerDice.getTotalValue();
		}
	}

	onAddMonsterHP(amount: number)
	{
		this.$store.dispatch(PLAYER_UPDATE_MONSTER_HP, amount);
		if (amount > 0)
		{
			this.$store.dispatch(SOUND_PLAY_SFX, SoundData.UI_Menu_HP_Gain);
		}
		else
		{
			this.$store.dispatch(SOUND_PLAY_SFX, SoundData.UI_Menu_HP_Loss);
		}
	}

}
