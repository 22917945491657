
import Vue from "vue";
import Component from "vue-class-component";
import { mapGetters, mapState } from "vuex";
import LoreChoiceView from "@/components/LoreChoiceView.vue";
import LoreCombatView from "@/components/LoreCombatView.vue";
import LoreMerchantView from "@/components/LoreMerchantView.vue";
// @ts-ignore
import loredata from "../../data/loredata.json";
import { SOUND_PLAY_SFX } from "@/helpers/consts";
import { SoundData } from "@/data/sounddata";

@Component({
    name: "LoreCardsView",
	components: {
		LoreChoiceView,
		LoreCombatView,
		LoreMerchantView,
	}
})
export default class LoreCardsView extends Vue {
	currentLore = null;
	currentLoreIndex = 0;
	numberLoreCards = 0;
	visitedLoreCards : number[] = [];

    mounted() {
		this.numberLoreCards = loredata.lore.length;
	}

	drawRandomLoreCard()
	{
		if (this.visitedLoreCards.length < this.numberLoreCards)
		{
			this.$store.dispatch(SOUND_PLAY_SFX, SoundData.UI_Card_Pickup);
			let newIndex = Math.floor(Math.random() * this.numberLoreCards);
			while (this.visitedLoreCards.indexOf(newIndex) > -1 || this.currentLoreIndex == newIndex)
			{
				newIndex = Math.floor(Math.random() * this.numberLoreCards);
			}
			this.currentLoreIndex = newIndex;
			this.visitedLoreCards.push(newIndex)
			this.currentLore = loredata.lore[this.currentLoreIndex];
		}
		else
		{
			this.shuffleCards()
			this.drawRandomLoreCard();
		}
	}

	shuffleCards()
	{
		this.$store.dispatch(SOUND_PLAY_SFX, SoundData.UI_Card_Shuffle);
		this.currentLore = null;
		this.visitedLoreCards = [];
	}
}
