import { DATABASE_CLEAR, DATABASE_SAVE, GAME_RESUME, GAME_SELECT_CHARACTER, GAME_SELECT_LANGUAGE, GAME_SELECT_NEXT_CHARACTER, GAME_SELECT_PREV_CHARACTER, GAME_INIT, GAME_SET_DIFFICULTY, GAME_UPDATE_LANGUAGE, PLAYER_UPDATE_STAT, PLAYER_CLEAR_STATE } from "@/helpers/consts";
import { clamp } from "@/helpers/utils";
import { router } from "@/router";
import { ActionContext, ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { store } from "..";
// @ts-ignore
import CharacterData from "../../data/characterdata.json";
// @ts-ignore
import DifficultyData from "../../data/difficulty.json";

const MutateDifficulty: string = 'gameModule:mutateDifficulty';
const MutateCharacter: string = 'gameModule:mutateCharacter';
const MutateLanguage: string = 'gameModule:mutateLanguage';
const StartGame: string = 'gameModule:startGame';

export interface GameState {
	difficultyLevel: number,
	selectedCharacter: number,
	language: string,
	hasStartedGame: boolean,
}

const state: GameState = {
	difficultyLevel: -1,
	selectedCharacter: -1,
	language: 'en',
	hasStartedGame: false,
}

const actions: ActionTree<GameState, any> = {
	[GAME_SET_DIFFICULTY]({ commit, state }: ActionContext<GameState, any>, value: number) {
		// console.warn(`ACTION - Game update difficulty`);
		commit(MutateDifficulty, value);
	},
	[GAME_SELECT_CHARACTER]({ commit, state }: ActionContext<GameState, any>, value: number) {
		// console.warn(`ACTION - Game select character`);
		commit(MutateCharacter, value);
	},
	[GAME_SELECT_NEXT_CHARACTER]({ commit, state }: ActionContext<GameState, any>) {
		// console.warn(`ACTION - Game select next character`);
		commit(MutateCharacter, state.selectedCharacter + 1);
	},
	[GAME_SELECT_PREV_CHARACTER]({ commit, state }: ActionContext<GameState, any>) {
		// console.warn(`ACTION - Game select prev character`);
		commit(MutateCharacter, state.selectedCharacter - 1);
	},
	[GAME_SELECT_LANGUAGE]({ commit, state }: ActionContext<GameState, any>, value: string) {
		// console.warn(`ACTION - Game select language`);
		commit(MutateLanguage, value);
		this.dispatch(DATABASE_SAVE);
	},
	[GAME_UPDATE_LANGUAGE]({ commit, state }: ActionContext<GameState, any>, value: string) {
		// console.warn(`ACTION - Game update language`);
		commit(MutateLanguage, value);
	},


	[GAME_INIT]({ commit, state }: ActionContext<GameState, any>) {
		// console.warn(`ACTION - Game START`);

		this.dispatch(DATABASE_CLEAR);
		commit(StartGame, true);

		UpdateStartingStats();

		router.push({ name: 'Game' });

	},
	[GAME_RESUME]({ commit, state }: ActionContext<GameState, any>) {
		// console.warn(`ACTION - Game RESUME`);
		commit(StartGame, true);
	},

}

const mutations: MutationTree<GameState> = {
	[MutateDifficulty](currentState: GameState, value: number) {
		currentState.difficultyLevel = clamp(value, 0, getDifficultyArray().length - 1);
	},
	[MutateLanguage](currentState: GameState, value: string) {
		currentState.language = value;
	},
	[MutateCharacter](currentState: GameState, value: number) {
		let index: number = value;
		if (index < 0) index = getCharacterArray().length - 1;
		if (index >= getCharacterArray().length) index = 0;
		currentState.selectedCharacter = index;
	},
	[StartGame](currentState: GameState, value: boolean) {
		state.hasStartedGame = value;
	},
}

const getters: GetterTree<GameState, any> = {

	getUnlocalizedDifficultyName: function (state) {
		return getDifficultyArray()[state.difficultyLevel];
	},
	getSelectedCharacterId: function (state) {
		return _getSelectedCharacterId();
	},
	getSelectedCharacterModifiers: function (state) {
		return _getSelectedCharacterModifiers();
	},	
	getSelectedCharacterArchetype: function (state) {
		return _getSelectedCharacterArchetype();
	}
}

export const GameModule: Module<GameState, any> = {
	state,
	actions,
	mutations,
	getters,
}

function getDifficultyArray(): string[] {
	return ["difficulty.normal.name",
		"difficulty.harder.name",
		"difficulty.roguelike.name",
		"difficulty.roguelikeng.name"];
}



function getCharacterArray(): string[] {
	return CharacterData.list;
}


function _getSelectedDifficultyId(): string {
	return DifficultyData.list[state.difficultyLevel];
}

function _getSelectedCharacterId(): string {
	if (state.selectedCharacter < 0) {
		return CharacterData.list[0];
	}
	return CharacterData.list[state.selectedCharacter];
}

function _getSelectedCharacterModifiers() {
	return CharacterData[_getSelectedCharacterId()].modifiers;
}

function _getSelectedCharacterArchetype(): string {
	return CharacterData[_getSelectedCharacterId()].archetype;
}

function UpdateStartingStats(): void {
	store.dispatch(PLAYER_CLEAR_STATE);

	const characterModList = CharacterData[_getSelectedCharacterId()].modifiers;
	characterModList.forEach((modifier: { type: string, value: string | number }) => {
		store.dispatch(PLAYER_UPDATE_STAT, modifier);
	});

	const difficultyModList = DifficultyData[_getSelectedDifficultyId()].modifiers;
	difficultyModList.forEach((modifier: { type: string, value: string | number }) => {
		store.dispatch(PLAYER_UPDATE_STAT, modifier);
	});


}