export enum SoundData {
	AMB_Sewers = "AMB_Sewers",
	AMB_Dungeon = "AMB_Dungeon",
	AMB_Catacombs = "AMB_Catacombs",
	AMB_SunkenKeep = "AMB_SunkenKeep",
	AMB_TheGate = "AMB_TheGate",
	AMB_TheStairs = "AMB_TheStairs",
	AMB_WindyClimb = "AMB_WindyClimb",
	AMB_VertigoHeights = "AMB_VertigoHeights",
	SEQUENCE_TEST = "SEQUENCE_TEST",
	UI_Menu_Food_Gain = "UI_Menu_Food_Gain",
	UI_Menu_Food_Loss = "UI_Menu_Food_Loss",
	UI_Menu_Gold_Loss = "UI_Menu_Gold_Loss",
	UI_Menu_Gold_Gain = "UI_Menu_Gold_Gain",
	UI_Menu_Armor_Loss = "UI_Menu_Armor_Loss",
	UI_Menu_Armor_Gain = "UI_Menu_Armor_Gain",
	UI_Menu_HP_Gain = "UI_Menu_HP_Gain",
	UI_Menu_HP_Loss = "UI_Menu_HP_Loss",
	UI_Menu_XP_Gain = "UI_Menu_XP_Gain",
	UI_Menu_XP_Loss = "UI_Menu_XP_Loss",
	UI_Menu_Potion_Fire_Gain = "UI_Menu_Potion_Fire_Gain",
	UI_Menu_Potion_Frost_Gain = "UI_Menu_Potion_Frost_Gain",
	UI_Menu_Potion_Poison_Gain = "UI_Menu_Potion_Poison_Gain",
	UI_Menu_Potion_Heal_Gain = "UI_Menu_Potion_Heal_Gain",
	UI_Menu_Potion_Holy_Gain = "UI_Menu_Potion_Holy_Gain",
	UI_Menu_Potion_Perception_Gain = "UI_Menu_Potion_Perception_Gain",
	UI_Menu_Potion_Loss = "UI_Menu_Potion_Loss",
	UI_Menu_Effect_Blindness = "UI_Menu_Effect_Blindness",
	UI_Menu_Effect_Curse = "UI_Menu_Effect_Curse",
	UI_Menu_Effect_Poison = "UI_Menu_Effect_Poison",
	UI_Menu_Effect_Remove = "UI_Menu_Effect_Remove",
	UI_Click = "UI_Click",
	UI_Die = "UI_Die",
	UI_Dice = "UI_Dice",
	UI_Card_Pickup = "UI_Card_Pickup",
	UI_Card_Shuffle = "UI_Card_Shuffle",
}
