<template>
    <section class="container is-fluid">
		<div class="mr_heading title hero-body has-text-centered">{{$t('menu.playeraid')}}</div>

		<!-- SETUP -->
        <b-collapse
            aria-id="contentIdForA11y2"
            class="panel"
            animation="slide"
            v-model="isSetupOpen">
            <template #trigger>
                <div
                    class="panel-heading"
                    role="button"
                    aria-controls="contentIdForA11y2">
                    <div class="mr_heading" v-html="$t('playeraid.setup')"></div>
                </div>
            </template>
            <div class="content mr_content">
				<ul>
                <li class="li" v-html="$t('playeraid.setup.1')"></li>
                <li class="li" v-html="$t('playeraid.setup.2')"></li>
                <li class="li" v-html="$t('playeraid.setup.3')"></li>
                <li class="li" v-html="$t('playeraid.setup.4')"></li>
                <li class="li" v-html="$t('playeraid.setup.5')"></li>
				</ul>
            </div>
        </b-collapse>

		<!-- TURN SEQUENCE -->
        <b-collapse
            aria-id="contentIdForA11y2"
            class="panel"
            animation="slide"
            v-model="isTurnSequenceOpen">
            <template #trigger>
                <div
                    class="panel-heading"
                    role="button"
                    aria-controls="contentIdForA11y2">
                    <div class="mr_heading" v-html="$t('playeraid.turnsequence')"></div>
                </div>
            </template>

            <div class="content mr_content">
				<div class="mr_subtitle" v-html="$t('playeraid.turnsequence.preparation.title')"></div>
				<ol>
					<li class="li" v-html="$t('playeraid.turnsequence.preparation.1')"></li>
					<li class="li" v-html="$t('playeraid.turnsequence.preparation.2')"></li>
					<li class="li" v-html="$t('playeraid.turnsequence.preparation.3')"></li>
				</ol>

				<div class="mr_subtitle" v-html="$t('playeraid.turnsequence.exploration.title')"></div>
				<ol>
					<li class="li" value="4" v-html="$t('playeraid.turnsequence.exploration.4')"></li>
					<li class="li" v-html="$t('playeraid.turnsequence.exploration.5')"></li>
					<li class="li" v-html="$t('playeraid.turnsequence.exploration.6')"></li>
				</ol>
				
				<div class="mr_subtitle" v-html="$t('playeraid.turnsequence.delving.title')"></div>
				<ol>
					<li class="li" value="7" v-html="$t('playeraid.turnsequence.delving.7')"></li>
					<li class="li" v-html="$t('playeraid.turnsequence.delving.8')"></li>
					<li class="li" v-html="$t('playeraid.turnsequence.delving.9')"></li>
					<li class="li" v-html="$t('playeraid.turnsequence.delving.10')"></li>
				</ol>
				
            </div>
        </b-collapse>

		<!-- POTIONS -->
        <b-collapse
            aria-id="contentIdForA11y2"
            class="panel"
            animation="slide"
            v-model="isPotionsOpen">
            <template #trigger>
                <div
                    class="panel-heading"
                    role="button"
                    aria-controls="contentIdForA11y2">
                    <div class="mr_heading" v-html="$t('playeraid.potions')"></div>
                </div>
            </template>

            <div class="content mr_content">
				<div class="mr_subtitle" v-html="$t('potions.type.offensive')"></div>
                <div class="li"><span class="content is-large mr_icons potion_fire">7</span> &nbsp;<strong><span v-html="$t('potions.fire.name')"></span> </strong><span v-html="$t('potions.fire.description')"></span></div>
                <div class="li"><span class="content is-large mr_icons potion_frost">8</span> &nbsp;<strong><span v-html="$t('potions.frost.name')"></span> </strong><span v-html="$t('potions.frost.description')"></span></div>
                <div class="li"><span class="content is-large mr_icons potion_poison">p</span> &nbsp;<strong><span v-html="$t('potions.poison.name')"></span> </strong><span v-html="$t('potions.poison.description')"></span></div>
				<hr>
				<div class="mr_subtitle" v-html="$t('potions.type.defensive')"></div>
                <div class="li"><span class="content is-large mr_icons potion_healing">H</span> &nbsp;<strong><span v-html="$t('potions.healing.name')"></span> </strong><span v-html="$t('potions.healing.description')"></span></div>
                <div class="li"><span class="content is-large mr_icons potion_holywater">9</span> &nbsp;<strong><span v-html="$t('potions.holy.name')"></span> </strong><span v-html="$t('potions.holy.description')"></span></div>
                <div class="li"><span class="content is-large mr_icons potion_perception">k</span> &nbsp;<strong><span v-html="$t('potions.perception.name')"></span> </strong><span v-html="$t('potions.perception.description')"></span></div>
            </div>
        </b-collapse>

		<!-- ICONS -->
        <b-collapse
            aria-id="contentIdForA11y2"
            class="panel"
            animation="slide"
            v-model="isIconsOpen">
            <template #trigger>
                <div
                    class="panel-heading"
                    role="button"
                    aria-controls="contentIdForA11y2">
                    <div class="mr_heading" v-html="$t('playeraid.icons')"></div>
                </div>
            </template>

            <div class="content mr_content">
				<div class="li"><span class="content is-large mr_icons">@</span> &nbsp;<strong><span v-html="$t('icons.player.name')"></span> </strong><span v-html="$t('icons.player.description')"></span></div>
				<div class="li"><span class="content is-large mr_icons">K</span> &nbsp;<strong><span v-html="$t('icons.refresh.name')"></span> </strong><span v-html="$t('icons.refresh.description')"></span></div>
				<div class="li"><span class="content is-large mr_icons">=</span> &nbsp;<strong><span v-html="$t('icons.room.name')"></span> </strong></div>
				<div class="li"><span class="content is-large mr_icons">_</span> &nbsp;<strong><span v-html="$t('icons.floor.name')"></span> </strong><span v-html="$t('icons.floor.description')"></span></div>
				<div class="li"><span class="content is-large mr_icons">v</span> &nbsp;<strong><span v-html="$t('icons.nothing.name')"></span> </strong><span v-html="$t('icons.nothing.description')"></span></div>
				<div class="li"><span class="content is-large mr_icons potion_holywater">B</span> &nbsp;<strong><span v-html="$t('icons.blessing.name')"></span> </strong><span v-html="$t('icons.blessing.description')"></span></div>
				<div class="li"><strong class="content is-large">↑10</strong><span class="content is-large mr_icons effect_regeneration">h</span> &nbsp;<strong><span v-html="$t('icons.topup.name')"></span> </strong><span v-html="$t('icons.topup.description')"></span></div>
            </div>
        </b-collapse>

		<!-- EFFECTS -->
        <b-collapse
            aria-id="contentIdForA11y2"
            class="panel"
            animation="slide"
            v-model="isEffectsOpen">
            <template #trigger>
                <div
                    class="panel-heading"
                    role="button"
                    aria-controls="contentIdForA11y2">
                    <div class="mr_heading" v-html="$t('playeraid.effects')"></div>
                </div>
            </template>

            <div class="content mr_content">
				<div class="li"><span class="content is-large mr_icons effect_curse">C</span> &nbsp;<strong><span v-html="$t('effects.curse.name')"></span> </strong><span v-html="$t('effects.curse.description')"></span></div>
				<div class="li"><span class="content is-large mr_icons effect_poison">P</span> &nbsp;<strong><span v-html="$t('effects.poison.name')"></span> </strong><span v-html="$t('effects.poison.description')"></span></div>
				<div class="li"><span class="content is-large mr_icons effect_blindness">l</span> &nbsp;<strong><span v-html="$t('effects.blindness.name')"></span> </strong><span v-html="$t('effects.blindness.description')"></span></div>
				<div class="li"><span class="content is-large mr_icons effect_ignore_armor">b</span> &nbsp;<strong><span v-html="$t('effects.ignorearmor.name')"></span> </strong><span v-html="$t('effects.ignorearmor.description')"></span></div>
				<div class="li"><span class="content is-large mr_icons effect_weaken">W</span> &nbsp;<strong><span v-html="$t('effects.weaken.name')"></span> </strong><span v-html="$t('effects.weaken.description')"></span></div>
				<div class="li"><span class="content is-large mr_icons effect_regeneration">Q</span> &nbsp;<strong><span v-html="$t('effects.regeneration.name')"></span> </strong><span v-html="$t('effects.regeneration.description')"></span></div>
				<div class="li"><span class="content is-large mr_icons effect_fall">F</span> &nbsp;<strong><span v-html="$t('effects.fall.name')"></span> </strong><span v-html="$t('effects.fall.description')"></span></div>
            </div>
        </b-collapse>

		<!-- COMBAT -->
        <b-collapse
            aria-id="contentIdForA11y2"
            class="panel"
            animation="slide"
            v-model="isCombat">
            <template #trigger>
                <div
                    class="panel-heading"
                    role="button"
                    aria-controls="contentIdForA11y2">
                    <div class="mr_heading" v-html="$t('playeraid.combat')"></div>
                </div>
            </template>

            <div class="content mr_content">
				<div class="mr_subtitle" v-html="$t('playeraid.combat.attackphase.player_title')"></div>
				<ol>
					<li class="li" v-html="$t('playeraid.combat.attackphase.1')"></li>
					<li class="li" v-html="$t('playeraid.combat.attackphase.2')"></li>
					<li class="li" v-html="$t('playeraid.combat.attackphase.3')"></li>
					<li class="li" v-html="$t('playeraid.combat.attackphase.4')"></li>
					<li class="li" v-html="$t('playeraid.combat.attackphase.5')"></li>
					<li class="li" v-html="$t('playeraid.combat.attackphase.6')"></li>
					<li class="li" v-html="$t('playeraid.combat.attackphase.7')"></li>
					<li class="li" v-html="$t('playeraid.combat.attackphase.8')"></li>
				</ol>

				<div class="mr_subtitle" v-html="$t('playeraid.combat.attackphase.monster_title')"></div>
				<ol>
					<li class="li" v-html="$t('playeraid.combat.attackphase.9')"></li>
					<li class="li" v-html="$t('playeraid.combat.attackphase.10')"></li>
					<li class="li" v-html="$t('playeraid.combat.attackphase.11')"></li>
				</ol>
            </div>
        </b-collapse>

		<!-- FEATS -->
        <b-collapse
            aria-id="contentIdForA11y2"
            class="panel"
            animation="slide"
            v-model="isFeatsOpen">
            <template #trigger>
                <div
                    class="panel-heading"
                    role="button"
                    aria-controls="contentIdForA11y2">
                    <div class="mr_heading" v-html="$t('playeraid.feats')"></div>
                </div>
            </template>
			<div class="content mr_content">
					<li v-html="$t('playeraid.feats.body')"></li>
			</div>
        </b-collapse>

		<!-- SKILL CHECKS -->
        <b-collapse
            aria-id="contentIdForA11y2"
            class="panel"
            animation="slide"
            v-model="isSkillChecksOpen">
            <template #trigger>
                <div
                    class="panel-heading"
                    role="button"
                    aria-controls="contentIdForA11y2">
                    <div class="mr_heading" v-html="$t('playeraid.skillchecks')"></div>
                </div>
            </template>
			<div class="content mr_content">
					<li v-html="$t('playeraid.skillchecks.body')"></li>
			</div>
        </b-collapse>


		<!-- CHARACTER SKILLS -->
        <b-collapse
            aria-id="contentIdForA11y2"
            class="panel"
            animation="slide"
            v-model="isCharSkillsOpen">
            <template #trigger>
                <div
                    class="panel-heading"
                    role="button"
                    aria-controls="contentIdForA11y2">
                    <div class="mr_heading" v-html="$t('playeraid.charskills')"></div>
                </div>
            </template>
			<div class="content mr_content">
				<li v-html="$t('playeraid.charskills.body')"></li>
				<ul>
					<li v-html="$t('playeraid.charskills.exploration')"></li>
					<li v-html="$t('playeraid.charskills.combat')"></li>
					<li v-html="$t('playeraid.charskills.preparation')"></li>
					<li v-html="$t('playeraid.charskills.passive')"></li>
				</ul>
			</div>
        </b-collapse>

	<br />
    </section>
</template>

<style lang="less" scoped>
@import "../style/base";

.panel-heading
{
	background-color: @color-black;
	color: @color-white;
}

</style>

<script>
    export default {
        data() {
            return {
				isSetupOpen: false,
                isTurnSequenceOpen: false,
                isPotionsOpen: false,
                isIconsOpen: false,
                isEffectsOpen: false,
                isCombat: false,
                isFeatsOpen: false,
                isSkillChecksOpen: false,
                isCharSkillsOpen: false,
            }
        }
    }
</script>
