
import { SoundData } from '@/data/sounddata';
import { DATABASE_CLEAR, SETTINGS_TOGGLE_HIDDEN_INFO, SETTINGS_TOGGLE_STRICT_RULES, SOUND_PLAY_SFX, SOUND_TOGGLE_MUTE, SOUND_UPDATE_AMB_VOLUME, SOUND_UPDATE_MASTER_VOLUME, SOUND_UPDATE_SFX_VOLUME } from '@/helpers/consts';
import Vue from 'vue'
import { Component } from 'vue-property-decorator';
import { mapState } from 'vuex';
@Component({
  name: "SettingsView",
  computed: {
	  ...mapState({
		  strictRules: (state: any) => state.SettingsModule.strictRules,
		  hiddenInfo: (state: any) => state.SettingsModule.hiddenInfo,
		  masterVolume: (state: any) => state.SoundModule.masterVolume,
		  sfxVolume: (state: any) => state.SoundModule.sfxVolume,
		  ambVolume: (state: any) => state.SoundModule.ambVolume,
		  isMuted: (state: any) => state.SoundModule.isMuted,
	  })
  },
  watch: {
	  strictRules: function(val)
	  {
		  // @ts-ignore
		  this.proxyStrictRules = val;
	  },
	  hiddenInfo: function(val)
	  {
		  // @ts-ignore
		  this.proxyHiddenInfo = val;
	  }
  }
})

export default class SettingsView extends Vue {
	proxyHiddenInfo: boolean = this.$store.state.SettingsModule.hiddenInfo;
	proxyStrictRules: boolean = this.$store.state.SettingsModule.strictRules;

	mounted()
	{
		this.proxyHiddenInfo = this.$store.state.SettingsModule.hiddenInfo;
		this.proxyStrictRules = this.$store.state.SettingsModule.strictRules;
	}

	ClearGame() {
		this.$store.dispatch(DATABASE_CLEAR);
		//@ts-ignore
		const clearDataDialogText: string = this.$i18n.t('settings.cleardata.dialog');
		this.$buefy.dialog.alert(`<span class="mr_heading">${clearDataDialogText}</span>`)

	}

	OnChangeMasterVolume(value: any)
	{
		this.$store.dispatch(SOUND_UPDATE_MASTER_VOLUME, value);
	}

	OnChangeSfxVolume(value: any)
	{
		this.$store.dispatch(SOUND_UPDATE_SFX_VOLUME, value);
	}

	OnChangeAmbientVolume(value: any)
	{
		this.$store.dispatch(SOUND_UPDATE_AMB_VOLUME, value);
	}
	
	ToggleStrictRules() {
		this.$store.dispatch(SETTINGS_TOGGLE_STRICT_RULES);
	}

	ToggleHiddenInfo() {
		this.$store.dispatch(SETTINGS_TOGGLE_HIDDEN_INFO);
	}

	OnToggleSound()
	{
		this.$store.dispatch(SOUND_TOGGLE_MUTE);
	}

	OnUI(someBool: boolean = false)
	{
		this.$store.dispatch(SOUND_PLAY_SFX, SoundData.UI_Click);
	}
}
