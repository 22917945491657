import { ContainerType } from "@/enums/ContainerType";
import Container from "@/sound/Container";
import RandomContainer from "@/sound/RandomContainer";
import SequenceContainer from "@/sound/SequenceContainer";
import * as Tone from 'tone';

export default abstract class ContainerFactory
{
	public static createContainer(containerType: ContainerType, channel: Tone.Channel, data: any): Container
	{
		switch (containerType)
		{
			case ContainerType.Random: 
				const randomContainer = new RandomContainer(channel);
				randomContainer.nbRememberedIDs = data.forgetAfter;
				return randomContainer;
				
			case ContainerType.Sequence: 
				const sequenceContainer = new SequenceContainer(channel);
				sequenceContainer.delayType = data.delay_type;
				sequenceContainer.isLoop = data.loop;
				sequenceContainer.crossfade = data.crossfade;
				return sequenceContainer;

			case ContainerType.Blend: 
				const blendContainer = new Container(channel);
				return blendContainer;

			default: return new Container(channel);
		}
	}

}