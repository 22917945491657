import { SFX_BASE } from '@/helpers/consts';
import _ from 'lodash';
import Container from './Container';
import * as Tone from 'tone';
import { log } from 'tone/build/esm/core/util/Debug';

export default class SoundContainer extends Container 
{
	private _sound: Tone.Player;
	private _panner: Tone.Panner;
	private _isLoaded: boolean = false;
	private _playWhenReady: boolean = false;
	private _isPlaying: boolean = false;
	private _userStopped: boolean = false;

	public get duration(): number 
	{
		return this._sound.buffer.duration;
	}

	constructor(soundFile: string, channel: Tone.Channel, isLoop: boolean = false) 
	{
		super(channel);
		this._sound = new Tone.Player();
		this._sound.autostart = false;
		this._sound.load(SFX_BASE + soundFile).then(() => {
			this.onLoaded();
		});

		this._panner = new Tone.Panner(-1).toDestination();
		this._sound
			// .connect(this._panner)
			.connect(channel);

		this._sound.loop = isLoop;
		this._sound.onstop = () => {this.onStop()};
	}

	override Play(volume: number = 1, rate: number = 1, pan: number = 0) 
	{
		this._panner.pan.value = pan;
		this._sound.playbackRate = rate;
		this._sound.volume.value = volume;

		if (!this._isLoaded)
		{
			this._playWhenReady = true;
		}
		else
		{
			this.onReady();
		}

		return this;
	}

	override Stop() 
	{
		this._userStopped = true;
		this._isPlaying = false;
		this._playWhenReady = false;
		this._sound.stop();
	}

	private onLoaded() 
	{
		this._isLoaded = true;
		this.onLoad.dispatch();

		if (this._playWhenReady)
		{
			this.onReady();
		}
	}

	private onReady()
	{
		if (this._isPlaying) {
			this.Stop();
		}

		this._isPlaying = true;
		this._sound.start();
	}

	private onStop()
	{
		if (!this._userStopped) {
			this.onEnd.dispatch();
		}
		else
		{
			this._userStopped = false;
		}
	}
}
