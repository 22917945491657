
import Vue from "vue";
import Component from "vue-class-component";
import { mapGetters, mapState } from "vuex";
import { Prop } from "vue-property-decorator";

@Component({
    name: "LoreCombatView",
	props: {
		lore: Object
	},
})
export default class LoreCombatView extends Vue {
	hideGameplayInformation = false;
}
