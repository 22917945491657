
import Vue from "vue";
import Component from "vue-class-component";
import { mapGetters, mapState } from "vuex";
import { GAME_SELECT_LANGUAGE, SOUND_PLAY_SFX, SOUND_STOP_SFX, SOUND_TOGGLE_MUTE } from "./helpers/consts";
import * as Tone from 'tone';
import { TranslateResult } from "vue-i18n";

@Component({
	name: "Tracker",
	computed: {
		...mapState({
			hasStartedGame: (state: any) => state.GameModule.hasStartedGame,
			dungeonFloor: (state: any) => state.PlayerModule.dungeonFloor,
			playerLevel: (state: any) => state.PlayerModule.level,
			isMuted: (state: any) => state.SoundModule.isMuted,
			isToneRunning: (state: any) => state.SoundModule.isToneRunning,
			loadedSounds: (state: any) => state.SoundModule.loadedSounds,
		}),
		...mapGetters(["getSelectedCharacterId", "getSelectedCharacterArchetype", "selectedMat"]),
	},
})
export default class App extends Vue
{

	locales = ["fr", "en"];

	localeMapWithFlags = {
		"fr": "🇫🇷 FR",
		"en": "🇬🇧 EN",
		"de" : "🇩🇪 DE",
		"es" : "🇪🇸 ES",
	}

	sidebarOpen = false;
	hasStartedGame: boolean;
	dungeonFloor: number;
	getSelectedCharacterId: string;
	getSelectedCharacterArchetype: string;
	playerLevel: number;
	isMuted: boolean;
	isToneRunning: boolean;
	loadedSounds: string;

	mounted()
	{
		//@ts-ignore
		const textString : string = this.$i18n.t("audio.loadsounds.text");
		//@ts-ignore
		const loadSoundsString: string = this.$i18n.t('audio.loadsounds.button');
		this.$buefy.snackbar.open({ type: 'is-warning', position: 'is-bottom', duration: 10000, message: textString, actionText: loadSoundsString });
	}
	
	OnToggleSound()
	{
		this.$store.dispatch(SOUND_TOGGLE_MUTE);
	}

	OnStopAmbience()
	{
		this.$store.dispatch(SOUND_STOP_SFX);

	}

	onChangeLanguage(language: string)
	{
		this.$i18n.locale = `${language}`;
		this.$store.dispatch(GAME_SELECT_LANGUAGE, language);
	}

	
}
