import Vue from 'vue'
import Buefy from 'buefy'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import SettingsView from '../views/SettingsView.vue'
import RulebookView from '../views/RulebookView.vue'
import PlayerAidView from '../views/PlayerAidView.vue'
import GameView from '../views/game/GameView.vue'
import SelectDifficultyView from '../views/new/SelectDifficultyView.vue'
import SelectCharacterView from '../views/new/SelectCharacterView.vue'
import * as localForage from 'localforage'
import 'buefy/dist/buefy.css'
import { store } from '@/store'
import { DATABASE_FETCH } from '@/helpers/consts'
import * as Tone from 'tone';

Vue.use(VueRouter);
Vue.use(Buefy);

const routes = [
	{
		path: "/",
		name: 'Home',
		component: Home
	},
	{
		path: "*",
		redirect: '/'
	},
	{
		path: '/new/difficulty',
		name: 'SelectDifficulty',
		component: SelectDifficultyView
	},

	{
		path: '/new/character',
		name: 'SelectCharacter',
		component: SelectCharacterView
	},

	{
		path: '/game',
		name: "Game",
		component: GameView,
	},


	{
		path: '/playeraid',
		name: 'PlayerAid',
		component: PlayerAidView
	},

	{
		path: '/rulebook',
		name: 'Rulebook',
		component: RulebookView
	},

	{
		path: '/settings',
		name: 'Settings',
		component: SettingsView
	},

	// {
	// 	path: '/about',
	// 	name: 'about',
	// 	// route level code-splitting
	// 	// this generates a separate chunk (about.[hash].js) for this route
	// 	// which is lazy-loaded when the route is visited.
	// 	component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
	// }
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

document.addEventListener('DOMContentLoaded', () =>
{

	// Get all "navbar-burger" elements
	const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);

	// Check if there are any navbar burgers
	if ($navbarBurgers.length > 0) {

		// Add a click event on each of them
		$navbarBurgers.forEach(el =>
		{
			el.addEventListener('click', () =>
			{

				// Get the target from the "data-target" attribute
				const target = el.dataset.target;
				const $target = document.getElementById(target);

				// Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
				el.classList.toggle('is-active');
				if ($target !== null) {
					$target.classList.toggle('is-active');
				}

			});
		});
	}		
});

const appDB = localForage.createInstance({ name: "Mini Rogue Companion App" });

appDB.ready(() =>
{
	// console.warn("DB is ready.");
	store.dispatch(DATABASE_FETCH);
}).catch((error) =>
{
	throw new Error(error);
})

export { router, appDB } 
