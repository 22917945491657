
import Vue from 'vue';
import Component from 'vue-class-component';
import Feat from "@/components/Feat.vue";
import { SOUND_PLAY_SFX } from '@/helpers/consts';
import { SoundData } from '@/data/sounddata';

@Component({
    name: 'Dice',
    components: {
        Feat,
    },
    props: {
        type: String,
		handle: String,
		isCursed: Boolean,
    },
	computed: {
		getLocButton: function() {
			// @ts-ignore
			return this.currentIndex === 5 && !this.$props.isCursed ? "dice.reroll" : "dice.feat";
		},
		getCanReroll: function()
		{
			// @ts-ignore
			return this.isPlayerDice && this.currentIndex === 5 && !this.$props.isCursed;
		},
		getCanFeat: function()
		{
			// @ts-ignore
			return this.isPlayerDice && !this.hasUsedFeat;
		},
		getNumberOfExplosions: function()
		{
			// @ts-ignore
			return "y".repeat(this.explosions);
		},
		getDungeonDieState: function()
		{
			// @ts-ignore
			if (this.isDungeonCrit)
			{
				return this.$i18n.t("general.crit");
			}

				// @ts-ignore
			if (this.isDungeonMiss)
			{
				return this.$i18n.t("general.miss");
			}

			return "";
		}
	}

})
export default class Dice extends Vue {
	dungeonDiceValues: string[] = ["1", "2", "3", "4", "5", "6"];
	playerDiceValues: string[] = ["q", "w", "e", "r", "t", "y"];
	poisonDiceValues: string[] = ["0", "0", "0", "P", "P", "P"];
	curseDiceValues: string[] = ["0", "0", "0", "C", "C", "C"];

	currentIndex: number = -1;
	currentValue: string = "0";
	currentDiceSet: string[] = [];

	isPlayerDice: boolean = false;

	isDungeonMiss: boolean = false;
	isDungeonCrit: boolean = false;

	hasUsedFeat: boolean = false;
	explosions: number = 0;

	rollCounter: number = 1;

	created()
	{
		switch (this.$props.type)
		{
			case "dungeon":
				this.currentDiceSet = this.dungeonDiceValues;
				break;
			case "player":
				this.currentDiceSet = this.playerDiceValues;
				this.isPlayerDice = true;
				break;
			case "poison":
				this.currentDiceSet = this.poisonDiceValues;
				break;
			case "curse":
				this.currentDiceSet = this.curseDiceValues;
				break;
			default:
				this.currentDiceSet = this.dungeonDiceValues;
			
		}

		this.$emit("register", this.$props.handle, this);

	}

	getTotalValue()  {
		// @ts-ignore
		return (this.explosions * 6) + this.currentIndex + 1;
	}

	getRandomDiceIndex(): number
	{
		const returnValue = Math.floor(Math.random() * 6);
		if (this.$props.type == "dungeon")
		{
			this.isDungeonMiss = false;
			this.isDungeonCrit = false;
			if (returnValue == 0) {
				this.isDungeonMiss = true;
			}
			if (returnValue == 5)
			{
				this.isDungeonCrit = true;
			}
		}
		return returnValue;
	}

	getDiceValue(): string
	{
		return this.currentDiceSet[this.currentIndex];
	}

	reset()
	{
		this.hasUsedFeat = false;
		this.explosions = 0;
	}

    roll(payload?: {reset: boolean}) {
		this.rollCounter++;
		if (payload && payload.reset) this.reset();
		this.currentIndex = this.getRandomDiceIndex();
		this.currentValue = this.getDiceValue();
		if (this.currentIndex == 0) {
			this.explosions = 0;
		}
    }

	onFeatExecuted(payload: {index: number, value: string}) 
	{
		this.explosions = 0;
		this.hasUsedFeat = true;
		this.currentIndex = payload.index;
		this.currentValue = payload.value;
		this.$emit("on-feat-executed");
	}

	openFeatDialog()
	{
		if (this.currentIndex == 5 && !this.$props.isCursed)
		{
			// reroll
			this.explosions++;
			this.roll({reset: false});
			this.$emit("on-feat-executed");
			this.$store.dispatch(SOUND_PLAY_SFX, SoundData.UI_Die);
		}
		else
		{
			// feat
			this.$buefy.modal.open({
				parent: this,
				component: Feat,
				trapFocus: true,
				canCancel: false,
				props: {
					currentValue: this.currentValue,
					handle: this.$props.handle,
				},
				events: {
					'on-feat-executed': this.onFeatExecuted,
				}
			})
		}
	}
}
