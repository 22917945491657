
import Vue from "vue";
import Component from "vue-class-component";
import Icon from '@/components/Icon.vue';
import { mapGetters, mapState } from "vuex";
import { Prop } from "vue-property-decorator";

@Component({
    name: "LoreMerchantView",
	props: {
		lore: Object
	},
	components: {
		Icon
	}
})
export default class LoreMerchantView extends Vue {
	hideGameplayInformation = false;
}
