import { DelayType } from '@/enums/DelayType';
import _ from 'lodash';
import Container from './Container';
import SoundContainer from './SoundContainer';


export default class SequenceContainer extends Container {
	
	private _ID = _.uniqueId("SequenceContainer_");
	private _currentIndex: number = -1;
	private _parentVolume: number;
	private _delayedSoundID: number = -1;

	public crossfade: number = 0;
	public isLoop: boolean = false;
	public delayType: DelayType = DelayType.OnEnd;
	public isPlaying: boolean;
	
	//#########################################
	// P U B L I C
	//#########################################
	override Play(volume: number = 1, rate: number = 1, pan:number = 0) : Container
	{
		if (!this.isPlaying) 
		{
			this.isPlaying = true;

			this._parentVolume = volume;
			this.playNext();
		}
		
		return this;
	}
	
	public override Stop(): void {
		this.isPlaying = false;
		super.Stop();
	}

	//#########################################
	// P R I V A T E
	//#########################################

	// Plays the next container from the array
	private playNext() 
	{
		// console.warn("Sequence Container Play Next", this._currentIndex);
		if (this._currentIndex < 0) 
		{
			this._currentIndex = 0;
		}
		console.assert(this._currentIndex >=0 && this._currentIndex < this.Count, "Sequence index must be within valid values.");
		
		
		const nextItem: Container = this.GetChildAt(this._currentIndex);
		nextItem[this.delayType].addOnce(this.sequenceStep);

		nextItem.Play(this._parentVolume * this.volume, 1, this.pan);
	}
	
	// Gets to the next step of the sequence
	// Which means it either plays the next sound, or declares itself "ended"
	private sequenceStep = () =>
	{
		// console.log(`sequenceStep : ${this._currentIndex}`);
		this._currentIndex = this._currentIndex + 1;
		
		if (this._currentIndex >= this.Count) 
		{
			this._currentIndex = 0;
			if (this.isLoop) 
			{
				// this.playNext();
				// _.delay(() => {
					this.playNext();
				// }, this.delay);
			}
			else
			{
				// @ts-ignore
				this[this.delayType].dispatch(-1);
			}
		}
		else
		{
			this.playNext();
		}
	}
}
